.selectAll {
  color: #464646;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.084px;
}

.deletedSelected {
  color: #ee505a !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.084px;
}

.headerProduct {
  overflow: hidden;
  color: #464646;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.072px;
}

.priceProduct {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.208px;
}

.discountProduct {
  color: #a6a2a2;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.084px;
  text-decoration-line: line-through;
}



.container label span:nth-of-type(2) {
  width: 100%;
}

.container label:not(:last-of-type) {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.container label:last-of-type {
  padding-top: 10px;
  padding-bottom: 10px;
}

.body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #00aaff, #0044cc);
  color: #fff;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('/InDevelop.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 95%;
  height: 95%;
}

.logo {
  margin-top: 10%;
  width: 25dvh;
  height: 25dvh;
  background: #ffee02;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: bounce 2s infinite;
}

.logo img {
  max-width: 80%;
  max-height: 80%;
}

.title {
  margin: 0;
  font-size: 2em;
  animation: fadeIn 1.5s ease-in  0.5s;
}

.description {
  margin-top: 10px;
  font-size: 1.2em;
  animation: fadeIn 1.5s ease-in 0.5s;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
